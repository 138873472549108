<template>
  <div style="height: 80px"><Header /></div>
  <div class="elv-pricing-plans-main">
    <div class="elv-pricing-plans-content">
      <div class="elv-pricing-plans-top">
        <div class="elv-pricing-plans-top__title">Pricing plans</div>
        <div class="elv-pricing-plans-top__sub-title">Only pay for you need scaling plans you can grow with.</div>
      </div>
      <div class="elv-pricing-plans-bottom">
        <div class="elv-pricing-plans-bottom-left">
          <div class="elv-pricing-plans-bottom-left-main">
            <div class="elv-pricing-plans-bottom-left-main__free">Free</div>
            <div class="elv-pricing-plans-bottom-left-main__desc">
              Kickstart product research in your business - at no cost
            </div>
            <div class="elv-pricing-plans-bottom-left-main__price">$ 0</div>
            <div class="elv-pricing-plans-bottom-left-main__duration">/Month</div>
            <div class="elv-pricing-plans-bottom-left-main__started" @click="onStartedClick()">
              Get Started for free
            </div>
            <div class="elv-pricing-plans-bottom-left-main__recommend">RECOMMEDNED FOR</div>
            <div class="elv-pricing-plans-bottom-left-main__recommend-desc">
              individuals & small teams interested in lightweight research.
            </div>
          </div>
        </div>
        <div class="elv-pricing-plans-bottom-right">
          <div class="elv-pricing-plans-bottom-right-main">
            <img src="@/assets/img/vip.png" alt="" class="elv-pricing-plans-bottom-right-main__vip" />
            <div class="elv-pricing-plans-bottom-right-main__title">Professional</div>
            <div class="elv-pricing-plans-bottom-right-main__desc">
              Scale continuous product discovery with your organization
            </div>
            <div class="elv-pricing-plans-bottom-right-main__chat">Let’s chat</div>
            <div class="elv-pricing-plans-bottom-right-main__pricing">Custom pricing</div>
            <div class="elv-pricing-plans-bottom-right-main__contact" @click="onTalkSalesClick()">Contact Sales</div>
            <div class="elv-pricing-plans-bottom-right-main__recommend">RECOMMENDED FOR</div>
            <div class="elv-pricing-plans-bottom-right-main__recommend-desc">
              Product pros looking to grow with more research power.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>
<script lang="ts" setup>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const router = useRouter()
const menuGlobalStore = useMenuGlobalStore()
const { firstPageUrl } = storeToRefs(menuGlobalStore)
const userGlobalStore = useUserGlobalStore()
const { isLogin } = storeToRefs(userGlobalStore)
const onTalkSalesClick = () => {
  window.open('https://www.elven.com/contacts', '_blank')
}

const onStartedClick = () => {
  if (isLogin.value) {
    router.push({
      path: `${firstPageUrl.value}`
    })
  } else {
    // 登录
    router.push({
      name: 'login'
    })
  }
}
</script>
<style lang="scss" scoped>
.elv-pricing-plans-main {
  width: 100%;
  margin: 45px 0 80px 0;

  .elv-pricing-plans-content {
    margin: 0 auto;
    width: 1440px;

    .elv-pricing-plans-top {
      margin-bottom: 40px;

      .elv-pricing-plans-top__title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        font-size: 30px;
        line-height: 30px;
        color: $elv-theme-base-text-color;
        text-align: center;
      }

      .elv-pricing-plans-top__sub-title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: $elv-color-333333;
        text-align: center;
        margin-top: 12px;
      }
    }

    .elv-pricing-plans-bottom {
      display: flex;
      justify-content: center;

      .elv-pricing-plans-bottom-left {
        background: $elv-color-FFFFFF;
        border: 1px solid $elv-color-CED7E0;
        border-radius: 16px;
        height: 566px;
        width: 420px;
        margin-right: 20px;
        margin-top: 4px;
        box-sizing: border-box;

        .elv-pricing-plans-bottom-left-main {
          margin-left: 44px;
          margin-right: 44px;

          .elv-pricing-plans-bottom-left-main__free {
            margin-top: 64px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 30px;
            line-height: 30px;
            color: $elv-theme-base-text-color;
          }

          .elv-pricing-plans-bottom-left-main__desc {
            margin-top: 8px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-color-838d95;
          }

          .elv-pricing-plans-bottom-left-main__price {
            margin-top: 48px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 40px;
            line-height: 40px;
            color: $elv-theme-base-text-color;
          }

          .elv-pricing-plans-bottom-left-main__duration {
            margin-top: 10px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-theme-base-text-color;
          }

          .elv-pricing-plans-bottom-left-main__started {
            margin-top: 44px;
            background: $elv-theme-color;
            border-radius: 48px;
            width: 276px;
            height: 56px;
            line-height: 56px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: #ffffff;

            &:hover {
              cursor: pointer;
            }
          }

          .elv-pricing-plans-bottom-left-main__recommend {
            margin-top: 50px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: $elv-theme-base-text-color;
          }

          .elv-pricing-plans-bottom-left-main__recommend-desc {
            margin-top: 12px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-color-838d95;
          }
        }
      }

      .elv-pricing-plans-bottom-right {
        background: url('@/assets/img/price_bg.png') no-repeat;
        background-size: 420px 570px;
        height: 570px;
        width: 420px;

        .elv-pricing-plans-bottom-right-main {
          margin-left: 44px;
          margin-right: 44px;

          .elv-pricing-plans-bottom-right-main__vip {
            margin-top: 36px;
            width: 24px;
            height: 24px;
          }

          .elv-pricing-plans-bottom-right-main__title {
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 30px;
            line-height: 30px;
            color: $elv-color-FFFFFF;
          }

          .elv-pricing-plans-bottom-right-main__desc {
            margin-top: 8px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-theme-icon-color;
          }

          .elv-pricing-plans-bottom-right-main__chat {
            margin-top: 48px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 40px;
            line-height: 40px;
            color: $elv-color-FFFFFF;
          }

          .elv-pricing-plans-bottom-right-main__pricing {
            margin-top: 8px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-color-FFFFFF;
          }

          .elv-pricing-plans-bottom-right-main__contact {
            margin-top: 44px;
            width: 276px;
            height: 56px;
            line-height: 56px;
            background: linear-gradient(229.88deg, #e99349 -39.58%, rgba(233, 147, 73, 0) 53.74%), #f4d3a5;
            border: 1px solid #ecc179;
            border-radius: 48px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: #411b00;

            &:hover {
              cursor: pointer;
            }
          }

          .elv-pricing-plans-bottom-right-main__recommend {
            margin-top: 50px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: $elv-color-FFFFFF;
          }

          .elv-pricing-plans-bottom-right-main__recommend-desc {
            margin-top: 12px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-theme-icon-color;
          }
        }
      }
    }
  }
}
</style>
